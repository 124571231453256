<template>
  <div>
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-lg-none">Contractors</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabColor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple>
            All
          </v-tab>
          <v-tab ripple>
            No bank details
          </v-tab>
          <v-tab ripple>
            <span class="d-md-none">No email</span> 
            <span class="d-none d-md-block">No email address</span> 
          </v-tab>
          <v-tab ripple>
            Insufficient funds
          </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-lg-block">
            <PageTitle>Contractors</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items v-model="tab" class="pt-2 tertiary">
            <v-tab-item>
              <Skeleton v-if="!allRecords"/>
              <ContractorsRecordsTable
                :headers="headers"
                v-if="allRecords"
                :tableData="allRecords"
                msg="All"
              />   
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!bankless"/>
              <ContractorsRecordsTable
                :headers="headers"
                v-if="bankless"
                :tableData="bankless"
                msg="Without bank details"
              />   
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!emailless"/>
              <ContractorsRecordsTable
                :headers="headers"
                v-if="emailless"
                :tableData="emailless"
                msg="Without email address"
              />  
            </v-tab-item>           
            <v-tab-item>
              <Skeleton v-if="!onHold"/>
              <ContractorsRecordsTable
                :headers="headers"
                v-if="onHold"
                :tableData="onHold"
                msg="Insufficient funds"
              />  
            </v-tab-item>  
          </v-tabs-items>
        </div>
      </transition>

      <v-row>
        <v-col cols="12">
          <v-expansion-panels multiple class="mt-8">
            <v-expansion-panel>
              <v-expansion-panel-header ripple class="secondary lighten-3 white--text" >
                <div class="d-flex align-center justify-start">
                  <!-- <v-icon class="mr-3" color="white">mdi-account-tie</v-icon> -->
                  <v-icon class="mr-3" color="white">mdi-tools</v-icon>
                  Edit Contractor
                </div>
                <template v-slot:actions>
                  <v-icon color="white">$expand</v-icon>
                </template>
                </v-expansion-panel-header>
              <v-expansion-panel-content>
                <EditContractor />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Skeleton from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'
import ContractorsRecordsTable from '../components/Contractors/ContractorsRecordsTable.vue'
import EditContractor from '@/components/Edits/EditContractor.vue'
import PageTitle from '@/components/Slots/PageTitle.vue'

export default {
  components: {
    Skeleton,
    ContractorsRecordsTable,
    EditContractor,
    PageTitle
  },
  data() {
    return {
      tab: null,
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'ContractorName',
        },
        { text: 'Balance', value: 'ContractorBalance', sortable: true, sort: (a, b) => b - a  },
        { text: 'Account no.', value: 'AccountNumber' },
        { text: '', value: 'actions', align: 'end' },
        // { text: 'Application', value: 'Invite' },
        // { text: 'Support', value: 'Message' },
      ],
    }
  },
  created() {
    this.$store.dispatch('fetchContractorRecords')
  },
  computed: {
    ...mapState({
        allRecords: state => state.ContractorsRecords.contractorsAll,
        bankless: state => state.ContractorsRecords.bankless,
        emailless: state => state.ContractorsRecords.emailless,
        onHold: state => state.ContractorsRecords.onHold,
    }),
  }
}
</script>