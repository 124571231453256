<template>
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div class="d-flex align-center">
            <v-icon large left color="white">mdi-tools</v-icon>
            <p class="subtitle-1 my-0">{{ tableData.Count }} Contractors</p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments(this.tableData) }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch
          @input="
            (newText) => {
              search = newText;
            }
          "
        />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="tableData.Data"
        :items-per-page="10"
        sort-by="ContractorName"
      >
        <!-- Contractor name with info dialog -->
        <template v-slot:item.ContractorName="{ item }">
          <a class="accent--text" @click="openInfoDialog(item)">{{
            item.ContractorName
          }}</a>
        </template>

        <!-- Display amount in: £10,000 format -->
        <template v-slot:item.ContractorBalance="{ item }">
          <span>
            {{ item | returnDisplayAmount }}
          </span>
        </template>

        <!-- Account Number -->
        <template v-slot:item.AccountNumber="{ item }">
          <span
            class="font-weight-thin"
            v-if="item.AccountNumber === 'Bank Details Incomplete'"
          >
            Incomplete
          </span>
          <span class="font-weight-thin" v-else>
            {{ item.AccountNumber }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0 secondary" dark>
              <v-list-item @click="fetchAccountSummary(item.OID)">
                <v-list-item-title
                  >View account summary</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item v-if="item.Email" @click="openActionDialog(item)">
                <v-list-item-title
                  >Invite contractor to use Contractor app</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-email</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Charge', item)">
                <v-list-item-title>
                  Apply Charge</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Refund', item)">
                <v-list-item-title>
                  Apply Refund</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              
              <v-list-item @click="navigateToPaymentRequests(item)">
                <v-list-item-title
                  >View payment requests</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-folder-plus-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="openDialog(item)">
                <v-list-item-title
                  >Contact support regarding this contractor</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-comment-text-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="requestOneOffPayment(item)">
        <v-list-item-title>Request One Off Payment</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-currency-gbp</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="fetchPendingInvoice(item.OID)">
                
                <v-list-item-title
                  >View latest pending invoice</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
              </v-list-item>
      <v-list-item
                @click.stop="openHistoricInvoicesDialog(item.OID)"
              >
                <v-list-item-title>Download latest remittances</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-download</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <!-- Pending invoice dialog -->
    <v-dialog v-model="pendingInvoiceDialog" max-width="100%">
      <PendingInvoiceCard
        :invoice="pendingInvoice"
        @closeDialog="closePendingInvoiceDialog"
      />
    </v-dialog>
    <MsgDialog
      :dialog="msgDialog"
      :details="selectedItem.ContractorName"
      :source="source"
      :OID="selectedItem.OID"
      @closeDialog="closeDialog"
      @msgSent="msgSent"
    />

    <Snackbar
      :snackbarDialog="snackbar"
      :snackbarText="snackbarText"
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

    
    <UserInfoDialog
      :dialog="infoDialog"
      :name="selectedItem.ContractorName"
      :email="selectedItem.Email"
      :number="selectedItem.Mobile"
      :fullDetails="selectedItem"
      :selectedProperties="selectedProperties"
      :isLoading="infoLoading"
      @closeDialog="closeInfoDialog"
    />
    <v-dialog v-model="accountSummaryPopup" max-width="100%">
      <PersonAccountPopup
        :entries="accountSummary"
        @closeDialog="closeAccountSummaryPopup"
      />
    </v-dialog>

    <v-dialog v-model="applyChargePopup" max-width="500px">
    <ApplyChargeOrRefundForm
        :actionToApply="chargeAction"
        :personID="selectedItem.OID"
        @closeDialog="closeChargePopup"
      />
    </v-dialog>

    <v-dialog v-model="actionDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="dialogColor white--text" color="secondary">
          Send email
          <v-spacer></v-spacer>
          <v-btn @click="actionDialog = false" text fab small color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-img src="@/assets/contractorApp.png" contain width="100%"></v-img>
          <p class="body-1 mt-5">
            Would you like to send
            <span class="primary--text">{{ selectedItem.ContractorName }}</span>
            an invitation to use the contractorApp?
          </p>
          <p class="body-1">
            You can find out more about the contractorApp
            <a
              href="https://agentos.com/contractor-app/"
              class="text-decoration-none"
              target="_blank"
              >here.</a
            >
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="sendInvitation"
            color="green d-inline"
            depressed
            width="100"
            class="mr-3 white--text"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="requestOneOffDialog" max-width="500px">
    <RequestPaymentDialog
        :selectedPaymentType="'One off payment'"
        :personID="selectedItem.OID"
        @closeRequestPaymentDialog="closeOneOffRequestPaymentDialog"
      />
    </v-dialog>
        <!-- historic invoices -->
        <v-dialog
      v-model="historicInvoiceDialog"
      width="500px"
      transition="dialog-transition"
    >
      <HistoricInvoicesCard
        :invoices="selectedHistoricInvoices"
        @closeDialog="closeHistoricInvoiceDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import MsgDialog from "@/components/Dialogs/MessageDialog";
import UserInfoDialog from "@/components/Dialogs/UserInfoDialog";
import Snackbar from "@/components/Snackbars/TheSnackbar";
import TableSearch from "@/components/TableComponents/TableSearch.vue";
import PersonAccountPopup from "@/components/BaseComponents/PersonAccountPopup.vue"
import ApplyChargeOrRefundForm from "../PaymentRequests/ApplyChargeOrRefundForm.vue";
import axios from "axios";
import RequestPaymentDialog from '../PaymentRequests/PaymentRequestDialog.vue'
import HistoricInvoicesCard from "@/components/OutgoingPayments/HistoricInvoicesCard.vue";
import PendingInvoiceCard from "@/components/OutgoingPayments/PendingInvoiceCard.vue";

export default {
  components: {
    MsgDialog,
    UserInfoDialog,
    Snackbar,
    TableSearch,
    PersonAccountPopup,
    ApplyChargeOrRefundForm,
    RequestPaymentDialog,
    HistoricInvoicesCard,
    PendingInvoiceCard
  },
  props: ["tableData", "headers", "showTotPayments", "msg"],
  data() {
    return {
      source: "Contractors",
      loading: false,
      search: "",
      selectedItem: "",
      msgDialog: false,
      infoDialog: false,
      actionDialog: false,
      invitationSubject: "AgentOS Contractor app",
      invtationBody: `Dear Tom%0d%0aYou can create an account in the Contractor app here: https://contractor.agentos.com%0d%0a`,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      timeout: 3000,
      loadingAccountsSummary: false,
      accountSummary: "",
      accountSummaryPopup: false,
      loadingChargePopup: false,
      chargeAction: "",
      applyChargePopup: false,
      requestOneOffDialog: false,
      selectedProperties: [],
      infoLoading: false,
      historicInvoiceDialog: false,
      selectedHistoricInvoices: "",
      pendingInvoiceDialog: false,
      pendingInvoice: "",
      loadingPendingInvoice: false,
    };
  },
  methods: {
    fetchPendingInvoice(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/outgoingPayments/${id}/invoice/pending`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.pendingInvoice = response.data;
          this.pendingInvoiceDialog = true;
          this.loadingPendingInvoice = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closePendingInvoiceDialog() {
      (this.pendingInvoice = ""), (this.pendingInvoiceDialog = false);
    },
    openHistoricInvoicesDialog(personID) {
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${personID}/invoices/historic`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedHistoricInvoices = response.data;
          this.historicInvoiceDialog = true;
        })
        .catch((err) => {
          console.log(err);
        });
      
      
    },
    closeHistoricInvoiceDialog() {
      this.selectedHistoricInvoices = "";
      this.historicInvoiceDialog = false;
    },
    navigateToPaymentRequests(item) {
      this.$router.push({ name: "Payments", params: { id: item.OID } });
    },
    closeSnackbar() {
      this.snackbar = false;
    },
    openDialog(item) {
      this.selectedItem = item;
      this.msgDialog = true;
    },
    openInfoDialog(item) {
      this.infoLoading=true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${item.OID}/invoices/properties`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedProperties = response.data;
          this.infoLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.selectedItem = item;
      this.infoDialog = true;
    },
    openActionDialog(item) {
      this.selectedItem = item;
      this.actionDialog = true;
    },
    closeActionDialog() {
      this.actionDialog = false;
      this.selectedItem = "";
    },
    closeDialog() {
      this.msgDialog = false;
      this.selectedItem = "";
    },
    closeInfoDialog() {
      this.selectedProperties = [];
      this.infoDialog = false;
      this.infoDialog = false;
      this.selectedItem = "";
    },
    sendInvitation() {
      window.location.href = `mailto:${this.selectedItem.Email}?subject=${this.invitationSubject}&body=Hi ${this.selectedItem.ContractorName},%0a%0aPlease setup your contractor account here: https://contractor.agentos.com to access work orders and payments.`;
      this.actionDialog = false;
    },
    msgSent(payload) {
      this.msgDialog = false;
      (this.snackbar = true), (this.snackbarColor = payload.color);
      this.snackbarText = payload.text;
      this.selectedItem = "";
    },
    totalPayments(value) {
      const array = value.Data;
      const payments = array.map((val) => {
        return val.ContractorBalance;
      });
      const total = payments.reduce((acc, val) => acc + val, 0);
      // return total.toLocaleString()
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(total);
    },
    fetchAccountSummary(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/company/person/${id}/account`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.accountSummary = response.data;
          this.accountSummaryPopup = true;
          this.loadingAccountsSummary = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAccountSummaryPopup() {
      (this.accountSummary = ""), (this.accountSummaryPopup = false);
    },
    applyCharge(actionToApply, item){
      this.chargeAction = actionToApply;
      this.selectedItem = item;
      this.applyChargePopup = true;
    },
    closeChargePopup(){
      this.chargeAction = "";
      this.applyChargePopup = false;
    },
    requestOneOffPayment(item){
      this.selectedItem = item;
      this.requestOneOffDialog = true;
    },
    closeOneOffRequestPaymentDialog(){
      this.requestOneOffDialog = false;
    }
  },
  filters: {
    returnDisplayAmount(val) {
      return val.DisplayContractorBalance;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
